.contact {
  background-color: var(--background-dark);
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 64px;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

#contactVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(10px); /* Adjust the blur value as needed */
}

.contact__banner {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
}

.contact__banner h2 {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 48px;
  color: #fff;
}

.span--font {
  font-size: 40px;
}

.contact__container {
  justify-self: center;
  align-self: center;
  margin-top: 64px;
  display: grid;
  grid-template-columns: 40% 50%;
  gap: 10%;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.contact-number {
  text-decoration: none;
  color: white;
}

.contact__option {
  background: var(--primary);
  color: var(--color-white);
  padding: 18px;
  border-radius: 18px;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
}

.contact__option:hover {
  background-color: transparent;
  border-color: var(--primary);
}

.contact__option-icon {
  font-size: 24px;
  margin-bottom: 8px;
}

.contact__option a {
  margin-top: 12px;
  display: inline-block;
}

/* ========================= FORM ======================= */

form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

input,
textarea {
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background: transparent;
  border: 2px solid var(--primary);
  resize: vertical;
  color: var(--color-white);
}

.msg-btn {
  font-size: 16px;
}

/* =============================== Media Queries ( Medium Devices ) =============================== */
@media screen and (max-width: 1024px) {
  .contact {
    background-color: var(--background-dark);
    display: flex;
    flex-direction: column;
    padding: 32px 0;
  }

  .contact__container {
    padding: 0 24px;
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .contact__banner h2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 48px;
  }
}

/* =============================== Media Queries ( Small Devices ) =============================== */
@media screen and (max-width: 600px) {
  .contact__container {
    padding: 0 16px;
    width: var(--max-width-small);
    margin-top: 32px;
  }

  .contact__banner {
    margin-top: 0;
    padding: 0 16px;
  }

  .contact__banner h2 {
    font-size: 28px;
  }

  .contact__banner span {
    font-size: 28px;
  }
}
