.featured {
  /* background: var(--color-primary-alt); */
  background: var(--color-dark-bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.featured--banner {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  padding: 2rem 0;
  width: 100%;
}

.featured--banner h2 {
  color: #26a69a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 3rem;
  width: 100%;
}

.featured--banner h2::after {
  content: "";
  height: 1px;
  width: 100%;
  background-color: #26a69a;
  margin: 1rem;
}

.featured--banner h2::before {
  content: "";
  height: 1px;
  width: 100%;
  background-color: #26a69a;
  margin: 1rem;
}

.featured--banner span {
  color: #fff;
  font-size: 2.5rem;
}

.featured__container {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .featured {
    padding: 1rem 0;
  }

  .featured__container {
    width: var(--container-width-medium);
  }
}

@media screen and (max-width: 600px) {
  .featured__container {
    width: var(--container-width-small);
  }

  .featured--banner {
    margin-top: 2rem;
    padding: 2rem 0;
  }

  .featured--banner h2 {
    font-size: 2rem;
  }

  .featured--banner span {
    font-size: 1.8rem;
  }
}