.about {
  background: var(--dark-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
}

.about__more span {
  color: #fff;
  font-size: 42px;
}

.about__container {
  display: grid;
  width: 100%;
  grid-template-columns: 25% 60%;
  gap: 15%;
  place-items: center;
  padding-bottom: 64px;
}

.about__content {
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.about__me {
  width: 100%;
  border-radius: 32px;
  background: var(--primary-alt);
  display: grid;
  place-items: center;
}

.about__me-image {
  width: 100%;
  border-radius: 32px;
  overflow: hidden;
}

.about__me-image > img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.skills__container {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 16px 16px 16px 16px;
  border-radius: 12px;
  background-color: var(--primary-alt);
}

.skills__content {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
}

.skills__title {
  font-size: 32px;
  line-height: 24px;
  color: white;
  padding: 16px 16px 0 16px;
  border-radius: 12px;
}

.skills__block {
  background: white;
  width: 100%;
  float: right;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px;
}

.skills__type {
  color: var(--primary);
  display: flex;
  flex-direction: row;
  font-size: 28px;
  line-height: 24px;
  padding-bottom: 16px;
  width: 100%;
  margin: 0;
}

.skills__tags_tags {
  list-style-type: none;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.skills__tags_tags li {
  font-size: 16px;
  float: left;
  display: block;
  color: white;
  background-color: var(--color-black);
  text-align: center;
  padding: 4px 14px;
  margin: 10px 10px 0px 0px;
  text-decoration: none;
  border-radius: 4px;
}

.tools__container {
  display: flex;
  gap: 32px;
  flex-direction: column;
  padding: 16px 16px 16px 16px;
  border-radius: 12px;
  background-color: var(--primary-alt);
  width: 100%;
}

.tools__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.tools__title {
  font-size: 32px;
  line-height: 24px;
  color: white;
  padding: 16px 16px 0 16px;
  border-radius: 12px;
}

.tools__block {
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 0;
  width: 100%;
}

.tools__type {
  display: flex;
  flex-direction: row;
  font-size: 28px;
  line-height: 24px;
  padding-bottom: 16px;
  width: 100%;
}

.tools__tools {
  /* display: flex;
  flex-wrap: wrap; */
  /* gap:64px; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  row-gap: 24px;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.tools__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
}

.tools__item__icon {
  font-size: 96px;
  color: var(--primary);
}

.tools--media {
  height: 100%;
  width: 55%;
  justify-self: right;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.video--play-when-visible-tools {
  display: block;
  width: 100%;
  height: 100%;
  border: 5px solid transparent;
  border-radius: 32px;
}

.about__more {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 48px;
}

.music__container {
  display: grid;
  grid-template-columns: 60% 25%;
  gap: 15%;
  place-items: center;
  padding-bottom: 64px;
}

.music__me {
  width: 100%;
  border-radius: 32px;
  background: linear-gradient(45deg, transparent, var(--primary), transparent);
  display: grid;
  place-items: center;
  height: max-content;
}

.music__me-image {
  border-radius: 32px;
  /* aspect-ratio: 1/1; */
  overflow: hidden;
  transform: rotate(10deg);
  transition: var(--transition);
  height: max-content;
}

.music__me-image > img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.twin-pic {
  margin-top: 32px;
  border-radius: 32px;
}

.music__me-image:hover {
  transform: rotate(0);
}

.music__content {
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.hobby__title {
  font-size: 36px;
  line-height: 32px;
  margin-bottom: 16px;
  color: var(--primary);
  padding: 0;
  position: relative;
}

.fly__container {
  display: grid;
  grid-template-columns: 25% 60%;
  gap: 15%;
  place-items: center;
  padding-bottom: 64px;
}

.fly__me {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 32px;
  background: linear-gradient(45deg, transparent, var(--primary), transparent);
  display: grid;
  place-items: center;
  height: max-content;
}

.fly__me-image {
  border-radius: 32px;
  aspect-ratio: 1/1;
  overflow: hidden;
  transform: rotate(10deg);
  transition: var(--transition);
  height: max-content;
}

.fly__me-image > img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.fly__me-image:hover {
  transform: rotate(0);
}

.fly__content {
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.funny__container {
  display: grid;
  grid-template-columns: 60% 25%;
  gap: 15%;
  place-items: center;
  padding-bottom: 64px;
}

.funny__content {
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.last__container {
  display: flex;
  gap: 32px;
  flex-direction: column;
}

.last__content {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
}

.last__block {
  background: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 24px;
}

.last__tags {
  list-style-type: none;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.last__tags_tags li {
  font-size: 16px;
  float: left;
  display: block;
  color: white;
  background-color: var(--color-black);
  text-align: center;
  padding: 2px 16px;
  margin: 4px 8px 4px 0px;
  text-decoration: none;
  border-radius: 4px;
}

/* =============================== Media Queries ( Medium Devices ) =============================== */
@media screen and (max-width: 1024px) {
  .about {
    padding: 0;
    gap: 32px;
  }

  .about__container {
    width: var(--container-width-md);
    display: flex;
    gap: 32px;
    flex-direction: column;
    padding-bottom: 64px;
    padding: 0;
    margin: 0;
  }

  .about__me {
    width: 48%;
    /* display: none; */
  }

  .skills__container {
    display: flex;
    gap: 16px;
    flex-direction: column;
    padding: 8px;
  }

  .skills__content {
    flex-direction: column;
  }

  .tools__container {
    display: flex;
    gap: 32px;
    flex-direction: column;
    padding: 8px;
  }

  .tools__tools {
    /* display: flex;
    flex-wrap: wrap; */
    /* gap:64px; */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    row-gap: 16px;
    justify-content: center;
    align-items: center;
  }

  .tools__item {
    font-size: 16px;
  }

  .tools__item__icon {
    font-size: 64px;
  }

  .music__container {
    display: flex;
    gap: 64px;
    flex-direction: column;
    padding-bottom: 64px;
  }

  .music__me {
    width: 75%;
    display: flex;
    justify-content: space-between;
  }

  .music__me-image {
    width: 48%;
    height: 100%;
  }

  .twin-pic {
    margin-top: 0;
  }

  .fly__container {
    display: flex;
    gap: 64px;
    flex-direction: column-reverse;
    padding-bottom: 64px;
  }

  .fly__me {
    width: 48%;
  }

  .funny__container {
    display: flex;
    gap: 64px;
    flex-direction: column;
    padding-bottom: 64px;
  }

  .funny__me {
    width: 50%;
  }

  .last__container {
    display: flex;
    gap: 64px;
    flex-direction: column;
    padding-bottom: 64px;
  }

  .last__content {
    flex-direction: column;
  }
}

/* =============================== Media Queries ( Small Devices ) =============================== */
@media screen and (max-width: 600px) {
  .about__container {
    width: var(--container-width-sm);
    display: flex;
    gap: 32px;
    flex-direction: column;
    padding-bottom: 64px;
    padding: 0;
    margin: 0;
  }

  .last__block {
    border-radius: 8px;
    padding: 8px;
  }

  .tools__tools {
    /* display: flex;
    flex-wrap: wrap; */
    /* gap:64px; */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    justify-content: center;
    align-items: center;
  }

  .tools__tools {
    /* display: flex;
    flex-wrap: wrap; */
    /* gap:64px; */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    row-gap: 12px;
    justify-content: center;
    align-items: center;
  }

  .tools__item {
    font-size: 12px;
  }

  .tools__item__icon {
    font-size: 64px;
  }

  .hobby__title {
    font-size: 28px;
    line-height: 28px;
    padding: 0 32px 0 0;
  }

  .skills__title {
    font-size: 28px;
    line-height: 28px;
    padding: 0 32px 0 0;
  }

  .tools__title {
    font-size: 28px;
    line-height: 28px;
    padding: 0 32px 0 0;
  }

  .about--banner span {
    color: #fff;
    font-size: 28px;
  }

  .about__more span {
    color: #fff;
    font-size: 28px;
  }

  .about--banner {
    margin-top: 32px;
    padding: 32px 0;
  }

  .about--banner h2 {
    font-size: 32px;
  }
}
