.rez-top-margin {
  margin-top: 64px;
}

video {
  width: 100%;
}

.rez-bot-margin {
  margin-bottom: 64px;
}

.rezcult-page-container {
  background-color: var(--dark-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rezcult {
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 100%;
}

.rezcult--banner {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 32px;
  background: var(--color-white);
}

.rezcult__banner__content {
  display: grid;
  grid-template-columns: 60% 35%;
  gap: 5%;
  place-items: center;
  padding-top: 32px;
}

.single-img-banner {
  display: block;
  width: 100%;
  object-fit: cover;
}

.rezcult--banner h1 {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 38px;
  color: var(--dark-background);
}

.rezcult--banner .span--font {
  font-size: 32px;
  color: var(--primary);
  padding-bottom: 32px;
}

.rezcult--banner p {
  width: 100%;
  color: var(--primary-alt);
  text-align: left;
  padding-bottom: 16px;
}

.rezcult-sec {
  background-color: var(--dark-background);
  border-radius: 32px;
}

.rezcult-sec h2 {
  color: var(--primary);
  font-size: 28px;
  font-weight: 600;
}

.rezcult-sec h3 {
  color: var(--primary);
  font-size: 24px;
  margin-top: 48px;
}

.rezcult-sec h4 {
  color: var(--primary-alt);
  font-size: 24px;
}

.rezcult-sec p {
  color: var(--color-white);
  padding-bottom: 16px;
}

.rezcult__manupilating {
  margin-top: 32px;
  display: grid;
  grid-template-columns: 60% 35%;
  gap: 5%;
  place-items: top;
}

.rezcult__manupilating .text {
  width: 100%;
}

.rezcult__videos__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 64px;
}

.rezcult__gallery {
  margin-bottom: 64px;
}

.twod {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.twin-img {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.twin-img img {
  width: 50%;
}

.single-img {
  width: 100%;
}

.rezcult__buts {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  gap: 16px;
}

.btn-rezcult {
  width: 100%;
  color: var(--color-white);
}

.vertical_vids_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

/* =============================== Media Queries ( Medium Devices for banner ) =============================== */
@media screen and (max-width: 1224px) {
  .rezcult__banner__content {
    display: flex;
    flex-direction: column;
    place-items: center;
    padding-top: 32px;
    max-width: var(--max-width-medium);
  }
}

/* =============================== Media Queries ( Medium Devices ) =============================== */
@media screen and (max-width: 1024px) {
  .rezcult-container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }

  .rezcult-sec {
    background-color: var(--dark-background);
    padding: 16px 0;
    max-width: var(--max-width-medium);

  }

  .vertical_vids_container {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* =============================== Media Queries ( Small Devices ) =============================== */
@media screen and (max-width: 600px) {
  .rezcult-container {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;
  }

  .rezcult-sec {
    background-color: var(--dark-background);
    padding: 16px 0px;
  }

  .rezcult--banner h1 {
    font-size: 24px;
    font-weight: 400;
  }

  .rezcult--banner .span--font {
    font-size: 24px;
  }

  .twin-img {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .twin-img img {
    width: 100%;
  }

  .vertical_vids_container {
    grid-template-columns: repeat(1, 1fr);
  }
}