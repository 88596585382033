/* body {
    margin: 0;
    font-size: 28px;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .header {
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    background-color: #f1f1f1;
  }
  
  .header h2 {
    text-align: center;
  } */
  
  .progress-container {
    width: 100%;
    height: 8px;
    background: rgb(207, 19, 19);
    position: absolute;
    top: 40rem;

  }
  
  .progress-bar {
    height: 8px;
    background: #04aa6d;
    width: 0%;
  }
  
  .content {
    padding: 100px 0;
    margin: 50px auto 0 auto;
    width: 80%;
  }
  