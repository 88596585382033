.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  /* Ensure the hero section takes at least the full viewport height */
  background-color: var(--dark-background);

  .hero__container {
    .hero__inner {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      padding: 64px 0;

      .buttons-container {
        display: flex;
        flex-direction: column;
      }

      .hero__content {
        width: 70%;

        .hero__content__inner {
          width: 80%;
          margin: 0 auto;
          color: var(--white);

          h1 {
            .hero__content__line {
              font-size: var(--heading-text-size);
              margin: 0;
              height: 48px;
              overflow: hidden;
            }
          }

          .hero__content__p {
            margin-top: 48px;
            padding-right: 48px;
            margin-bottom: 56px;
            color: var(--primary-alt);
          }

          .hero__info__buts {
            position: relative;
          }
        }
      }

      .hero__images {
        width: 30%;
        //   height: 68vh;
        position: relative;
        margin-right: 32px;

        .hero__images__inner {
          height: 100%;

          .hero__img {
            width: 100%;
            height: 100%;
            background: url("./hero-assets/profile-webp.webp");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 20px 20px 400px 400px;

            img {
              display: none;
              position: absolute;
              top: auto;
              bottom: auto;
              width: 100%;
              border-radius: 80px 80px 320px 320px;
            }
          }
        }
      }
    }
  }
}

/* ==================== MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
  .hero {
    visibility: hidden;
    height: 68vh;
    /* Use 'height' instead of 'min-height' */
    padding: 0;
    max-width: var(--max-width-medium);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    /* Center horizontally within its parent */

    .hero__container {
      display: flex;
      justify-items: center;
      align-items: center;

      .hero__inner {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        position: relative;
        height: 100%;
        padding: 64px 0;
        padding-bottom: 0;

        .hero__content {
          width: 100%;
          height: 100%;

          .hero__content__inner {
            width: 100%;
            height: 100%;
            margin: 0 auto;
            color: var(--white);
            margin-right: 48px;

            h1 {
              .hero__content__line {
                font-size: 32px;
                margin: 0;
                height: 48px;
                overflow: hidden;
              }
            }

            .hero__content__p {
              margin-top: 48px;
              padding-right: 48px;
              margin-bottom: 56px;
            }

            .hero__info__buts {
              position: relative;
            }
          }
        }

        .hero__images {
          width: 70%;
          //   height: 68vh;
          position: relative;
          margin-right: 32px;
          height: 80px;

          .hero__images__inner {
            height: 100%;

            .hero__img {
              width: 100%;
              height: 100%;
              background: url("./hero-assets/profile-webp.webp");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              border-radius: 100px 100px 200px 200px;

              img {
                display: none;
                position: absolute;
                top: auto;
                bottom: auto;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

/* ==================== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
  .hero {
    visibility: hidden;
    min-height: 68vh;
    padding-bottom: 32px;
    padding-top: 28.8px;
    max-width: var(--max-width-small);
    margin-top: 38px;

    .hero__container {
      .hero__inner {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        position: relative;
        height: 100%;
        padding: 0;

        .hero__content {
          width: 100%;
          height: 100%;

          .hero__content__inner {
            width: 100%;
            height: 100%;
            margin: 0 auto;

            h1 {
              .hero__content__line {
                display: block;
                font-size: 22.4px;
                margin: 0;
                height: 33.6px;
                overflow: hidden;
              }
            }

            h1::before {
              content: "";
              width: 72px;
              height: 72px;
              background: url("./hero-assets/profile-webp.webp");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              border-radius: 50%;
              float: left;
              margin-right: 16px;
            }

            .hero__content__p {
              font-size: 0.9rem;
            }

            @media screen and (max-width: 389px) {
              h1 {
                .hero__content__line {
                  font-size: 20.8px;
                  height: 30.4px;
                }
              }

              h1::before {
                width: 64px;
                height: 64px;
                margin-right: 12.8px;
              }
            }

            @media screen and (max-width: 374px) {
              h1 {
                .hero__content__line {
                  font-size: 17.6px;
                  height: 27.2px;
                }
              }

              h1::before {
                width: 51.2px;
                height: 51.2px;
                margin-right: 9.6px;
              }
            }

            .hero__content__p {
              margin-top: 16px;
              margin-bottom: 16px;
              padding: 0;
            }

            .hero__info__buts {
              position: relative;
            }
          }
        }

        .hero__images {
          display: none;
          width: 70%;
          //   height: 68vh;
          position: relative;
          margin-right: 32px;
          height: 80px;

          .hero__images__inner {
            height: 100%;

            .hero__img {
              width: 100%;
              height: 100%;
              background: url("./hero-assets/profile-webp.webp");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              border-radius: 100px 100px 200px 200px;

              img {
                display: none;
                position: absolute;
                top: auto;
                bottom: auto;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
