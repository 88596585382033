.boratas-page-container {
  background-color: var(--dark-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  max-width: var(--max-width-desktop);
}

.boratas {
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 100%;
}

.boratas--banner {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 32px;
  background: var(--color-white);
}

.boratas__banner__content {
  display: grid;
  grid-template-columns: 60% 35%;
  gap: 5%;
  place-items: center;
  padding-top: 32px;
}

.single-img-banner {
  display: block;
  width: 100%;
  object-fit: cover;
}

.boratas--banner h1 {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 38px;
  color: var(--color-black);
}

.boratas--banner .span--font {
  font-size: 24px;
  color: var(--primary);
  padding-bottom: 32px;
}

.boratas--banner p {
  width: 100%;
  color: var(--primary-alt);
  text-align: left;
  padding-bottom: 16px;
}

.boratas-sec {
  background-color: var(--background-dark);
  padding: 24px 0;
}

.boratas-sec h2 {
  color: var(--primary);
  font-size: 28.8px;
  font-weight: 600;
}

.boratas-sec h3 {
  color: var(--primary);
  font-size: 26px;
  margin-top: 48px;
}

.boratas-sec h4 {
  color: var(--primary);
  font-size: 22px;
  margin-top: 24px;
}

.boratas-sec p {
  color: var(--color-white);
  padding-bottom: 8px;
}

.boratas__manupilating {
  margin-top: 32px;
  display: grid;
  grid-template-columns: 60% 35%;
  gap: 5%;
  place-items: top;
}

.boratas__manupilating .text {
  width: 100%;
}

.boratas__videos__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 64px;
}

.boratas__gallery {
  margin-bottom: 64px;
}

.single-img {
  width: 100%;
}

.boratas__buts {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  gap: 16px;
}

.btn-boratas {
  width: 100%;
  color: var(--color-white);
}

/* =============================== Media Queries ( Medium Devices for photo manipulating ) =============================== */
@media screen and (max-width: 1528px) {
  .boratas__manupilating {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    place-items: center;
  }
}

/* =============================== Media Queries ( Medium Devices for banner ) =============================== */
@media screen and (max-width: 1224px) {
  .boratas__banner__content {
    display: flex;
    flex-direction: column;
    place-items: center;
    padding-top: 32px;
    max-width: var(--max-width-medium);
  }
}

/* =============================== Media Queries ( Medium Devices ) =============================== */
@media screen and (max-width: 1024px) {
  .boratas-container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }

  .boratas-sec {
    padding: 16px 0;
    max-width: var(--max-width-medium);
  }
}

/* =============================== Media Queries ( Small Devices ) =============================== */
@media screen and (max-width: 600px) {
  .boratas-container {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;
  }

  .boratas-sec {
    padding: 16px 0px;
  }

  .boratas--banner h1 {
    font-size: 26px;
    font-weight: 400;
  }

  .boratas--banner .span--font {
    font-size: 22px;
  }
}
