.rends-top-margin {
  margin-top: 64px;
}

.rends-bot-margin {
  margin-bottom: 64px;
}

.rends-page-container {
  background-color: var(--dark-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rends {
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 100%;
}

.rends--banner {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 32px;
  background: var(--color-white);
}

.rends__banner__content {
  display: grid;
  grid-template-columns: 60% 35%;
  gap: 5%;
  place-items: center;
  padding-top: 32px;
}

.single-img-banner {
  display: block;
  width: 100%;
  object-fit: cover;
}

.rends--banner h1 {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 38px;
  color: var(--color-black);
}

.rends--banner .span--font {
  font-size: 32px;
  color: var(--primary);
  padding-bottom: 32px;
}

.rends--banner p {
  width: 100%;
  color: var(--primary-alt);
  text-align: left;
  padding-bottom: 16px;
}

.rends-sec {
  background-color: var(--dark-background);
  border-radius: 32px;
}

.rends-sec h2 {
  color: var(--primary);
  font-size: 28px;
  font-weight: 600;
}

.rends-sec h3 {
  color: var(--primary);
  font-size: 24px;
  margin-top: 48px;
}

.rends-sec h4 {
  color: var(--primary-alt);
  font-size: 22px;
}

.rends-sec p {
  color: var(--color-white);
  padding-bottom: 16px;
}

.rends__manupilating {
  margin-top: 32px;
  display: grid;
  grid-template-columns: 60% 35%;
  gap: 5%;
  place-items: top;
}

.rends__manupilating .text {
  width: 100%;
}

.rends__videos__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 64px;
}

.rends__gallery {
  margin-bottom: 64px;
}

.twod {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.twin-img {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.twin-img img {
  width: 50%;
}

.single-img {
  width: 100%;
}

.rends__buts {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  gap: 16px;
}

.btn-rends {
  width: 100%;
  color: var(--color-white);
}

.vertical_vids_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.threed {
  margin-top: 32px;
}

/* =============================== Media Queries ( Medium Devices for banner ) =============================== */
@media screen and (max-width: 1224px) {
  .rends__banner__content {
    display: flex;
    flex-direction: column;
    place-items: center;
    padding-top: 32px;
    max-width: var(--max-width-medium);
  }
}

/* =============================== Media Queries ( Medium Devices ) =============================== */
@media screen and (max-width: 1024px) {
  .rends-container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }

  .rends-sec {
    background-color: var(---dark-background);
    padding: 16px 0;
    max-width: var(--max-width-medium);
  }

  .vertical_vids_container {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* =============================== Media Queries ( Small Devices ) =============================== */
@media screen and (max-width: 600px) {
  .rends-container {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;
  }

  .rends-sec {
    background-color: var(--dark-background);
    padding: 16px 0px;
  }

  .rends--banner h1 {
    font-size: 24px;
    font-weight: 400;
  }

  .rends--banner .span--font {
    font-size: 22px;
  }

  .twin-img {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .twin-img img {
    width: 100%;
  }

  .vertical_vids_container {
    grid-template-columns: repeat(1, 1fr);
  }
}
