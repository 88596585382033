.featured-block {
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: max-content;
  margin: 8px;
}

.featured__block__content {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: 100%;
  position: relative;
}

.featured-block--info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  max-height: 100%;
  width: 100%;
  justify-self: flex-start;
  padding: 8px 8px 0px 8px;
}

.featured-block--info h3 {
  font-size: 24px;
  line-height: 24px;
}

.featured-block--media {
  height: auto;
  width: 100%;
  justify-self: right;
  align-self: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.video--play-when-visible {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.tags {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 8px;
  padding: 0;
  overflow: hidden;
}

.tags li {
  font-size: 12px;
  float: left;
  display: block;
  color: white;
  background-color: var(--color-black);
  text-align: center;
  padding: 4px 12px;
  margin: 0px 10px 0px 0px;
  text-decoration: none;
  border-radius: 4px;

}

/* =========================== Meida Queery (Med Devices) ======================== */

@media screen and (max-width: 1024px) {}

/* =========================== Meida Queery (Small Devices) ======================== */

@media screen and (max-width: 600px) {

  .featured-block {
    margin: 4px;
    padding: 0;
  }

  .featured-block--info {
    padding: 4px 8px 0px 4px;
  }

  .tags {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    overflow: hidden;
    margin: 0;
  }

  .tags li {
    font-size: 8px;
    float: left;
    display: block;
    color: white;
    background-color: var(--color-black);
    text-align: center;
    padding: 2px 8px;
    margin: 0px 5px 5px 0px;
    text-decoration: none;
  }

}